import "./Footer.styles.scss";
import React from "react";

export default function Footer() {
  return (
    <center>
      <div className="Footer_Container">
        <section>
          <span>
            Copyright ©2022 All rights reserved | This template is made with by
            Colorlib
          </span>
        </section>
        <section>
          <div>
            <a href="/">Home</a>
            <a href="/">Recipes</a>
            <a href="/">About Us</a>
            <a href="/">Blog</a>
            <a href="/">Contact</a>
          </div>
        </section>
      </div>
    </center>
  );
}

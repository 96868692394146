import img10 from '../../assets/images/blogf10.jpg'
import img7 from '../../assets/images/blogf7.jpg'
import img8 from '../../assets/images/blogf8.jpg'
import img9 from '../../assets/images/blogf9.jpg'
import img11 from '../../assets/images/f1.jpg'


export const Data = [
    {
        title: 'Fried eggs with ham',
        img: img8,
        date: 'july 11, 2018 / BY Julia Stiles'
    },
    {
        title: 'Burger with fries',
        img: img7,
        date: 'july 11, 2018 / BY Julia Stiles'
    },
    {
        title: 'Avocado & Oisters',
        img: img9,
        date: 'july 11, 2018 / BY Julia Stiles'
    },
    {
        title: 'Tortilla Prawns',
        img: img11,
        date: 'july 11, 2018 / BY Julia Stiles'
    },
    {
        title: 'Burger with cockroach',
        img: img10,
        date: 'july 11, 2018 / BY Julia Stiles'
    },
]
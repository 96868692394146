import img3 from '../../assets/images/blogf3.jpg'
import img5 from '../../assets/images/blogf5.jpg'
import img10 from '../../assets/images/blogf10.jpg'
import img7 from '../../assets/images/blogf7.jpg'
import img8 from '../../assets/images/blogf8.jpg'
import img9 from '../../assets/images/blogf9.jpg'
import img11 from '../../assets/images/f1.jpg'

export const Data = [
    {
        tag: 'the best',
        title: 'Friends egg with ham',
        date: 'july 11, 2018 / BY Julia Stiles',
        img: img3,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tristique justo id elit bibendum pharetra non vitae lectus. Mauris libero felis, dapibus a ultrices sed, commodo vitae odio. Sed auctor tellus quis arcu tempus.'
    },
    {
        tag: 'the best',
        title: 'Mushrooms with pork chop',
        date: 'july 11, 2018 / BY Julia Stiles',
        img: img5,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tristique justo id elit bibendum pharetra non vitae lectus. Mauris libero felis, dapibus a ultrices sed, commodo vitae odio. Sed auctor tellus quis arcu tempus.'
    },
    {
        tag: 'the best',
        title: 'Birthday cake with chocolate',
        date: 'july 11, 2018 / BY Julia Stiles',
        img: img10,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tristique justo id elit bibendum pharetra non vitae lectus. Mauris libero felis, dapibus a ultrices sed, commodo vitae odio. Sed auctor tellus quis arcu tempus.'
    },
]

export const Data2 = [
    {
        title: 'Avocado & Oisters',
        img: img8,
        date: 'july 11, 2018 / BY Julia Stiles'
    },
    {
        title: 'Burger with fries',
        img: img7,
        date: 'july 11, 2018 / BY Julia Stiles'
    },
    {
        title: 'Tortilla pawns',
        img: img9,
        date: 'july 11, 2018 / BY Julia Stiles'
    },
    {
        title: 'Mashed Potatoes',
        img: img11,
        date: 'july 11, 2018 / BY Julia Stiles'
    },
]
import blogf1 from '../../assets/images/blogf1.jpg';
import blogf2 from '../../assets/images/blogf2.jpg';
import blogf3 from '../../assets/images/blogf3.jpg';
import blogf4 from '../../assets/images/blogf4.jpg';
import blogf5 from '../../assets/images/blogf5.jpg';
import blogf6 from '../../assets/images/blogf6.jpg';
import blogf7 from '../../assets/images/blogf7.jpg';
import blogf8 from '../../assets/images/blogf8.jpg';
import blogf9 from '../../assets/images/blogf9.jpg';
import blogf10 from '../../assets/images/blogf10.jpg';

export const data = [
    { 
        id: 1,
        imgSrc: blogf1,
    },
    {
        id: 2,
        imgSrc: blogf2,
    },
    {
        id: 3,
        imgSrc: blogf3,
    },
    {
        id: 4,
        imgSrc: blogf4,
    },
    {
        id: 5,
        imgSrc: blogf5,
    },
    {
        id: 6,
        imgSrc: blogf6,
    },
    {
        id: 7,
        imgSrc: blogf7,
    },
    {
        id: 8,
        imgSrc: blogf8,
    },
    {
        id: 9,
        imgSrc: blogf9,
    },
    {
        id: 10,
        imgSrc: blogf10,
    },
]
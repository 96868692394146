import { useState } from "react";
import {
  ImTwitter,
  ImDribbble,
  ImBehance,
  ImLinkedin2,
  ImFacebook,
  ImSearch,
  ImMenu,
} from "react-icons/im";
import { BsPinterest } from "react-icons/bs";
import "./Navbar.styles.scss";

export default function Navbar() {
    const [dropDown, setDropDown] = useState(false)
  return (
    <div className="Navbar_Container">
      <div className="header">
        {/* Navbar Icons */}
        <div className="header-top">
          <div className="header-icons">
            <a href="/">
              <BsPinterest />
            </a>
            <a href="/">
              <ImFacebook />
            </a>
            <a href="/">
              <ImTwitter />
            </a>
            <a href="/">
              <ImDribbble />
            </a>
            <a href="/">
              <ImBehance />
            </a>
            <a href="/">
              <ImLinkedin2 />
            </a>
          </div>

          {/* Navbar Search */}
          <div className="navbar-search">
            <div className="search-bar">
              <input type="text" name="search" placeholder="Search" size="40" />
              <button id="search">
                <ImSearch />
              </button>
            </div>
          </div>
        </div>
      </div>
      <nav>
        {/* Navbar Title */}
        <div className="navbar-header-text">
          <p className="navbar-header-text-p1">Bueno</p>
          <p className="navbar-header-text-p2">Food Blog</p>
        </div>

        {/* Navbar */}
        <div className="navbar">
          <button id="menu">
            <ImMenu />
          </button>

          <main>
            <ul>
              <li id="home">
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#aboutus">About Us</a>
              </li>
              <li>
                <button className="pages" onMouseOver={() => setDropDown((prev) => true)}
                    onMouseLeave={() => setDropDown((prev) => false)}>Pages</button>
                <div className={dropDown === false ? "dropdown" : "dropdown show"}  onMouseOver={() => setDropDown((prev) => true)}
                    onMouseLeave={() => setDropDown((prev) => false)}>
                  <ul>
                    <li>
                      <a href="/">Page 1</a>
                    </li>
                    <li>
                      <a href="/">Page 2</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="#recipes">Recipes</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>

            <button id="login" href="#Login/Register">
              Login/Register
            </button>
          </main>
        </div>
      </nav>
    </div>
  );
}
